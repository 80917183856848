import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Heart, Search, ShoppingBag } from "react-feather"
import { StaticImage } from "gatsby-plugin-image"

const Header = ({ siteTitle }) => (
  <div className="full-bleed layouted bg-black text-white mb-4">
    <header className="flex items-center justify-between h-24 px-4 md:px-0">
      <div className="branding flex items-center">
        <StaticImage className="w-12 mr-4" src="https://media.kubric.io/api/assetlib/06e60a46-35a6-4234-bb8b-961d4a615b39.png" alt="garnet logo"/>
        <Link to="/">
          <h1 className="text-2xl">{siteTitle}</h1>
        </Link>
      </div>
      <nav className="hidden md:inline-block">
        <Link className="mx-2 opacity-60 hover:opacity-80" to="/">
          NEW
        </Link>
        <Link className="mx-2 opacity-60 hover:opacity-80" to="/">
          MEN
        </Link>
        <Link className="mx-2 opacity-60 hover:opacity-80" to="/">
          WOMEN
        </Link>
        <Link className="mx-2 opacity-60 hover:opacity-80" to="/">
          PLUS
        </Link>
      </nav>
      <div className="flex">
        <Link className="mx-2" to="/">
          <Heart />
        </Link>
        <Link className="mx-2" to="/">
          <ShoppingBag />
        </Link>
        <Link className="mx-2" to="/">
          <Search />
        </Link>
      </div>
    </header>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
